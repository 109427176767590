<!-- 业界动态 -->

<template>
  <div>
    <div class="news-page">
      <img src="../../assets/images/news/news-ban.png" />
      <div class="news-content">
        <div class="left-nav">
          <router-link to="/">首页</router-link>
          <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>
          <router-link to="presscenter">新闻中心</router-link>
          <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>
          <router-link to="industryTrends">业界新闻</router-link>
        </div>
      </div>
      <div class="news-main">
        <div class="news-content">
          <div class="news-box">
            <ul>
              <li>
                <div @click="addskip7">
                  <h2>关于做好2020年畜禽粪污资源化利用工作的通知</h2>
                  <div class="time">2020-07-23</div>
                  <div class="font">
                    农业部、财政部近日联合发布《关于做好2020年畜禽粪污资源化利用工作的通知》，明确要求，要积极协调落实好沼气发电上网、生物天然气并入城市管网、用地用电等政策，为畜禽粪污资源化利用项目落地和运行提供支持保障。
                  </div>
                </div>
              </li>
              <li>
                <div @click="addskip11">
                  <h2>国家“十四五”生态环境政策改革重点与创新路径研究</h2>
                  <div class="time">2020-07-23</div>
                  <div class="font">
                    摘要：“十三五”时期，我国生态环境政策改革与创新加速，生态环境政策体系建设取得重大进展。“十四五”时期，需要继续深化生态环境保护政策改革与创新，推进生态环境治理体系和能力现代化。论文在对“十三五”时期生态环境政策进展分析研判的基础上，综合考虑“十四五”时期生态环境政策改革形势，提出了“十四五”生态环境政策改革思路与框架，建议“十四五”生态环境政策改革要抓好十大重点任务，包括结构调整、空间管控、质量管理、市场政策、治理格局、全球合作等六大重点领域的政策改革以及夯实严密法治、统一监管执法、提升执行能力、强化科技支撑等四项政策实施配套措施。
                  </div>
                </div>
              </li>
              <li>
                <div @click="addskip10">
                  <h2>
                    RCR新文：植物油和动物脂肪生产可再生柴油对全球暖化潜势的影响
                  </h2>
                  <div class="time">2020-07-10</div>
                  <div class="font">
                    植物脂肪可以转化为可再生生物柴油，以满足可再生能源需求。
                    2．大豆油生产可再生生物柴油尽管间接导致土地利用变化，但可以减少温室气体排放。
                    3．从头计算法用于模拟化学反应的热力学数据。 4.
                    热力学计算用于建立生命周期清单模型。
                  </div>
                </div>
              </li>
              <li>
                <div @click="addskip6">
                  <h2>
                    农村生活垃圾分类减量资源化模式及其影响因素与管理对策
                    ——以杭州市为例
                  </h2>
                  <div class="time">2020-07-06</div>
                  <div class="font"></div>
                </div>
              </li>
              <li>
                <div @click="addskip9">
                  <h2>北京、深圳5月1日起开始垃圾分类 谁是下一个风口？</h2>
                  <div class="time">2020-05-12</div>
                  <div class="font">
                    从5月1日起，北京、深圳正式实施垃圾分类相关条例。
                    记者梳理发现，今年以来，西安、南昌、广州等省会城市已相继出台垃圾分类相关政策，给出了落地时间表。根据住建部发布的《关于加快推进部分重点城市生活垃圾分类工作的通知》，2020年底46个城市将基本建成垃圾分类处理系统。
                  </div>
                </div>
              </li>
              <li>
                <div @click="addskip8">
                  <h2>修订后的固体废物污染环境防治法 自2020年9月1日起施行</h2>
                  <div class="time">2020-05-12</div>
                  <div class="font">
                    4月29日，十三届全国人大常委会第十七次会议审议通过了修订后的固体废物污染环境防治法（以下简称固废法），自2020年9月1日起施行。
                  </div>
                </div>
              </li>
            </ul>
            <div class="paging">
              <el-pagination :total="6" show-less-items />
            </div>
          </div>
          <div class="news-class">
            <h4>推荐新闻</h4>
            <router-link to="corporateNews">企业新闻</router-link>
            <router-link
              to="industryTrends"
              style="background: #3fa4e7; color: #fff"
              >业界动态</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {};
  },
  methods:{
    addskip1() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 1,
        },
      });
    },
    addskip2() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 2,
        },
      });
    },
    addskip3() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 3,
        },
      });
    },
    addskip4() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 4,
        },
      });
    },
    addskip5() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 5,
        },
      });
    },
    addskip6() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 6,
        },
      });
    },
    addskip7() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 7,
        },
      });
    },
    addskip8() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 8,
        },
      });
    },
    addskip9() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 9,
        },
      });
    },
    addskip10() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 10,
        },
      });
    },
    addskip11() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 11,
        },
      });
    },
  }
};
</script>
  <style scoped>
.news-page img {
  display: block;
  margin: auto;
}
.news-class {
  width: 23%;
  background: #fff;
  float: left;
  overflow: hidden;
  margin-left: 20px;
}
.news-class h4 {
  font-size: 22px;
  background: #8cc6ee;
  color: #fff;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
}
.news-class a {
  width: 94%;
  margin: auto;
  display: block;
  padding: 10px 0 10px 20px;
  color: #444;
  border-bottom: 1px solid #eee;
  font-size: 16px;
}

.paging {
  margin: auto;
  width: 94%;
  padding: 50px 10px;
  text-align: center;
}
.news-box ul li {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px dotted #0e6eb8;
  float: left;
}
.news-box ul li h2 {
  font-size: 20px;
  color: #222;
  line-height: 44px;
  overflow: hidden;
  height: 44px;
  transition: color 0.3s;
}
.news-content .news-box {
  width: 74%;
  float: left;
}
.news-box ul li a {
  display: block;
  width: 100%;
  padding: 20px 0;
}
.news-box ul li .time {
  color: #999;
  line-height: 26px;
  font-size: 14px;
  padding-left: 30px;
  background: url(../../assets/images/news/case31\ \(1\).png) no-repeat left center;
}
.news-box ul li .font {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  overflow: hidden;
}
.news-main {
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding:10px;
}
.news-page {
  overflow: hidden;
  min-width: 1250px;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}
.news-content {
  width: 1300px;
  margin: auto;
}
.left-nav{
    float: left;
    margin-top: 30px;
    margin-bottom:10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: bold;
    padding-left: 20px;
    color: #000;
  }
</style>
  